import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CommonCard from "../card";
import line from "../../assets/1.png"

const homestays = [
  {
    id: 1,
    title: "Indus Valley Homestay",
    location: "Mukteshwar, Nainital",
    imgSrc: `https://d2r3u9c7j9d9om.cloudfront.net/homestaycardtheshubhamgroup3.png`,
    link: "http://www.indusvalleyhomestay.com",
  },
];

function Project2_1_Homestay() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div data-aos-anchor-placement="top-center" data-aos="fade-up">
      <div
            className="text-3xl text-center cel2 md:mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent
"
          >
        Homestay
        <img src={line} alt="" className=" mx-auto h-4 mt-3" />
      </div>

      <div className="flex flex-col">
        <section className="grid gap-5 m-12 mt-4 md:m-auto p-3 md:gap-20">
          {homestays.map((homestay) => (
            <div className="w-full" key={homestay.id}>
              <CommonCard
                title={homestay.title}
                location={homestay.location}
                imgSrc={homestay.imgSrc}
                link={homestay.link}
              />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

export default Project2_1_Homestay;

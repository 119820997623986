import React, { useEffect, useRef } from "react";
import About from "../../newcomponents/home/About";
import MainHeading from "../MainHeading";
import CompanyParts from "./CompanyParts";
// import img from "../../assets/photos/logoBanner2.png";
import img from "../../assets/walloffame.jpg"
import OurProjects from "./Our_Projects";
import AOS from "aos";
import "aos/dist/aos.css";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
// import Gradient from "../../newcomponents/home/Gradient"
import SimpleCarousel from "../../newcomponents/home/SimpleCarousel";

function Home() {
  const containerRef = useRef(null);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div
      className=""
      ref={containerRef}
      data-aos-anchor-placement="top-center"
      data-aos="fade-up"
    >
      {/* <Banner /> */}
      <SimpleCarousel/>
      <Box className="bg-contain bg-center flex flex-col md:gap-10 md:p-5 pt-0 w-full">
        <MainHeading />
        {/* <Gradient/> */}
        {/* <About /> */}
        <About/>
        <OurProjects />
        <CompanyParts />
        <img
          data-scroll
          data-scroll-section
          data-scroll-speed=".1"
          className="w-full"
          src={img}
          alt="our brands"
        />
        <Helmet>
          <title>The Shubham Group</title>
          <meta
            name="description"
            content="The Shubham Group serves the best real estate solutions. The Shubham Group offers residential properties in prime locations like Mukteshwar, Chail and Ranikhet"
          />
        </Helmet>
      </Box>
    </div>
  );
}

export default Home;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container } from "@mui/material";
import CommonCard from "../card";
import Grid from "@mui/material/Grid2";
import config from "../../config";
import line from "../../assets/1.png"

const resorts = [
  {
    id: 1,
    title: "Neverland Resort",
    location: "Mukteshwar, Nainital",
    imgSrc: `https://d6pltnqzug32h.cloudfront.net/never.png`,
    link: "http://www.neverlandresorts.com",
  },
  {
    id: 2,
    title: "Chail Heights Resorts",
    location: "Chail, Himachal Pradesh",
    imgSrc: `${config.S3_URL}/ChailResortLogo.png`,
    link: "http://www.chailheightsresort.com",
  },
];

function Project2_Resorts() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div data-aos-anchor-placement="top-center" data-aos="fade-up">
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
        <div
            className="text-3xl text-center cel2 md:mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent
"
          >
            Upcoming Series Of Resorts
            <img src={line} alt="" className=" mx-auto h-4 mt-3" />
          </div>
        </section>

        {/* Resort Cards */}
        <div className="flex flex-col gap-0">
          <section className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 m-8 mt-0 md:m-auto p-3 ">
            {resorts.map((resort, index) => (
              <div className="w-full" key={index}>
              <CommonCard
                title={resort.title}
                location={resort.location}
                imgSrc={resort.imgSrc}
                link={resort.link}
              />
            </div>
          ))}
        </section>
        </div>
      </Container>
    </div>
  );
}

export default Project2_Resorts;

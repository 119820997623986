import React from "react";
// import bg from "../../assets/photos/TSGOC_Website-page1.jpg";
import Project1Residential from "./Project1_Residential";
import Project2Resorts from "./Project2_Resorts";
import Project3Trading from "./Project3_Trading";
import ProjectDelivered from "./ProjectDelivered";
import ProjectHomestay from "./Project2_1_Homestay";
import ProjectUpcomingResidential from "./ProjectUpcomingResidential";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import ProjectBenquet from "./ProjectBenquet";
import line from "../../assets/1.png";
import { useLocation } from "react-router-dom";


function Our_Projects() {


  const location = useLocation()

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
    >
      <Helmet>
        <title>Explore Our Projects- Properties In Prime Locations Of India</title>
        <meta
          name="description"
          content="See the range of successful projects by The Shubham Group. Our real estate portfolio demonstrates our commitment to excellence and client satisfaction."
        />
      </Helmet>
      <h3
        className={`text-3xl ${location.pathname === "/our-projects" ? "mt-12" : "md:mt-2"} mt-12 mx-3 flex flex-col items-center md:text-5xl md:pt-5 bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent  uppercase font-bold text-center cel`}
      >
        Explore Our Projects

        <img src={line} alt="" className=" h-5 mt-3" />
      </h3>

      <Project1Residential />
      <div className="flex flex-col md:gap-10">
        <ProjectUpcomingResidential />
        <Project2Resorts />
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:gap-16 md:grid-cols-2 lg:grid-cols-3 justify-center">
          <ProjectHomestay />
          <ProjectBenquet />
          <Project3Trading />
        </div>
        <ProjectDelivered />
      </div>
    </div>
  );
}

export default Our_Projects;

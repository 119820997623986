import React from "react";

const CommonCard = ({ title, location, imgSrc, link, onClick }) => {
  return (
    <div
      className=" w-full md:w-86 max-w-xs mx-auto rounded-2xl overflow-hidden shadow-lg transform transition-transform duration-500 hover:scale-95 flex flex-col justify-between h-full"
      onClick={onClick}
    >
      <a href={link} target="_blank" rel="noreferrer" className="flex-grow">
        <img
          src={imgSrc}
          alt={title}
          className="w-full md:h-64 md:object-cover object-contain object-center"
        />
        <div className="px-4 py-2">
          <h3 className="text-lg md:text-2xl font-serif font-bold text-gray-800">{title}</h3>
          <p className="text-sm md:text-md text-gray-600 arvo mt-2 font-serif">{location}</p>
        </div>
      </a>
    </div>
  );
};

export default CommonCard;
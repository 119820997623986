import React from 'react'
import TSGOC_LOGO_F_8 from "../assets/logos/SHUBHUM GROUP-LastLogo.png";
import icon_facebook from "../components/screens/icons2/icon_facebook.png";
import icon_twitter from "../components/screens/icons2/icon_twitter.png";
import icon_insta from "../components/screens/icons2/icon_insta.png";
import icon_yt from "../components/screens/icons2/icon_yt.png";
import icon_linkedin from "../components/screens/icons2/icon_linkedin.png";

function Footer() {
    return (
        <div className=' container mx-auto mt-12'>
            <div className='w-full flex border-b-2 mb-4 pb-4 justify-center items-center'>
                <div

                    className="gap-5 md:gap-6 flex"
                >
                    <a href="https://www.facebook.com/theshubhamGroup/" rel="noreferrer" target="_blank">
                        <img
                            className="w-8 md:w-12 md:p-1 md:rounded-lg animate__zoomIn2 "
                            src={icon_facebook}
                            alt="Facebook Link.."
                        />
                    </a>

                    <a href="https://twitter.com/theshubhamgroup" rel="noreferrer" target="_blank">
                        <img
                            className="w-8 md:w-12 md:p-1 md:rounded-lg animate__zoomIn2 "
                            src={icon_twitter}
                            alt="Twitter Link.."
                        />
                    </a>

                    <a href="https://www.instagram.com/theshubhamgroup/" rel="noreferrer" target="_blank">
                        <img
                            className="w-8 md:w-12 md:p-1 md:rounded-lg animate__zoomIn2 "
                            src={icon_insta}
                            alt="Insta Link.."
                        />
                    </a>

                    <a
                        href="https://www.youtube.com/@indusvalleymukteshwar"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            className="w-8 md:w-12 md:p-1 md:rounded-lg animate__zoomIn2 "
                            src={icon_yt}
                            alt="Youtube Link.."
                        />
                    </a>

                    <a
                        href="https://www.linkedin.com/in/the-shubham-group-of-company/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            className="w-8 md:w-12 md:p-1 md:rounded-lg animate__zoomIn2 "
                            src={icon_linkedin}
                            alt="LinkedIn Link.."
                        />
                    </a>
                </div>
            </div>

            <div className='w-full grid grid-cols-1 md:grid-cols-4 gap-8 justify-center  mb-12  p-4'>
                <div className='w-full flex flex-col gap-5 items-center justify-center'>
                    <img src={TSGOC_LOGO_F_8} alt='footer logo' className='md:h-28 h-20 object-contain' />
                </div>
                <div className='w-full'>
                    <h3 className='text-lg md:text-2xl font-bold text-gray-800 mb-3 md:text-start cel text-center'>Registered Office</h3>

                    <address className='text-gray-600 md:text-xl text-md md:text-start arvo text-center not-italic'>
                        The Shubham Group,  A-24, <br /> Okhla Industrial Area, Phase-2, <br /> Delhi, Pincode - 110020
                    </address>
                </div>
                <div className='w-full'>
                    <h3 className='text-lg md:text-2xl  font-bold text-gray-800 mb-3 md:text-start cel text-center'>Corporate Address</h3>
                    <a href='https://maps.app.goo.gl/dNzTgKr58UaUHy767' target='_blank' rel='noreferrer'>
                        <address className='text-gray-600 md:text-xl text-md hover:text-red-700 text-md md:text-start arvo text-center not-italic'>
                            B - 315, <br /> Sector-105 Noida, <br /> Uttar Pradesh, Pincode - 201304
                        </address>
                    </a>
                </div>
                <div className='w-full'>
                    <h3 className='text-lg md:text-2xl font-bold text-gray-800 mb-3 md:text-start cel text-center'>Contact Us</h3>

                    <a
                        href="mailto:info@theshubhamgroup.com"
                        className="block arvo md:text-xl text-md text-center md:text-start hover:text-red-700"
                    >
                        info@theshubhamgroup.com
                    </a>
                    <a
                        href="tel:+918510850101"
                        className="block arvo md:text-xl text-md text-center md:text-start hover:text-red-700"
                    >
                        85 10 85 01 01
                    </a>
                </div>
            </div>

            <p className="text-xs text-center py-4">
                © {new Date().getFullYear()} The Shubham Group. All rights reserved.
            </p>
        </div>
    )
}

export default Footer
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container } from "@mui/material";
import CommonCard from "../card";
import Grid from "@mui/material/Grid2";
import config from "../../config";
import line from "../../assets/1.png"

function Project1_Residential() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  const projects = [
    {
      title: "Indus Valley",
      location: "Mukteshwar, Nainital",
      imgSrc: `${config.S3_URL}/mukteshwarLogo.png`,
      link: "http://www.indusvalleymukteshwar.com",
    },
    // {
    //   title: "Queens Land",
    //   location: "Ranikhet",
    //   imgSrc: `${config.S3_URL}/queensland.png`,
    //   link: "https://indusvalleyranikhet.com/",
    // },
    {
      title: "Chail Heights Villas",
      location: "Chail, Himachal Pradesh",
      imgSrc: `${config.S3_URL}/ChailVillaLogo.png`,
      link: "http://www.chailheightsvillas.com",
    },
  ];

  return (
    <div
      data-aos-anchor-placement="top-center"
      data-aos="fade-up"
      className="mt-12 "
    >
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
          <div
            className="text-3xl text-center cel2 mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent
"
          >
            Ongoing Residential Projects
            <img src={line} alt="" className=" mx-auto h-4 mt-3" />
          </div>
        </section>
        <div className="flex flex-col gap-0">
          <section className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 m-8 -mt-3 md:m-auto p-3 ">
            {projects.map((project, index) => (
              <div className="w-full" key={index}>
                <CommonCard
                  title={project.title}
                  location={project.location}
                  imgSrc={project.imgSrc}
                  link={project.link}
                />
              </div>
            ))}
          </section>
        </div>
      </Container>
    </div>
  );
}

export default Project1_Residential;

import React from "react";
import icon_whatsapp from "./screens/icons2/icon_whatsapp.png";
import icon_call from "./screens/icons2/icon_call.png";

const Floating = () => {
  return (
    <div className="fixed bottom-5 z-50 right-5 flex flex-col space-y-3">
      <a href="tel:+918510850101">
        <img
          src={icon_call}
          alt="whatsapp"
          className="w-10 h-10 transform hover:scale-110 transition duration-300"
        />
      </a>
      <a href="https://wa.me/+918510850101">
        <img
          src={icon_whatsapp}
          alt="whatsapp"
          className="w-10 h-10 transform hover:scale-110 transition duration-300"
        />
      </a>
    </div>
  );
};

export default Floating;

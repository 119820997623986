import React from "react";
import BlogCard from "./BlogCard";
import { Helmet } from "react-helmet";
import line from "../../assets/1.png"
import blog1 from "../../assets/blog-the-shubham.webp"
import blog2 from "../../assets/blog-second-home.webp"
import blog3 from "../../assets/blog3.png"
import blog4 from "../../assets/blog+shubham+group.jpg"

const Blogs = () => {
  const blogPost = [{
    image: blog1,
    title: "The Shubham Group's Journey",
    date: "July 25, 2024",
    href: "/blog/the-shubham-group-journey",
  },
  {
    image: blog2,
    title: "Why second homes are the Future of Smart Real Estate Investment",
    date: "August 03, 2024",
    href: "/blog/second-homes-concept",
  },{
    image: blog3,
    title:
      "Collaboration and construction expansion the Shubham Group diversification",
    date: "October 3, 2024",
    href: "/blog/collaboration-construction-expansion",
  },{
    image: blog4,
    title:
      "Need of the hour : Investment in Real Estate",
    date: "November 12, 2024",
    href: "/blog/need-of-the-hour",
  }]

  return (
    <div className="flex flex-col gap-3 md:gap-10 px-5 md:px-20 mb-20">
      <h1 className="w-fit mx-auto felx flex-col items-center text-4xl md:text-5xl py-5 pt-16 cel bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-bold text-center p-3">
        OUR BLOGS
      <img src={line} alt="" className=" mx-auto h-5 md:h-6"/>
      </h1>

      <Helmet>
        <title>Our Blogs</title>
        <meta
          name="description"
          content="Dive into The Shubham Group’s blog section for the latest news, insights, and tips in Real Estate. Stay updated with our latest articles"
        />
      </Helmet>

      <div className="grid grid-cols-1 md:grid-cols-3 m-8 md:m-0 gap-10">
        

      {
      blogPost.map((e,i)=>(
        <BlogCard image={e.image} title={e.title} date={e.date} href={e.href} key={i} />
      ))
    }
      </div>
    </div>
  );
};

export default Blogs;

import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import config from '../../config';
// import walloffame from "../../assets/walloffame.jpg"

const SimpleCarousel = ({ interval = 2000 }) => {
  const images1 = [
    `${config.S3_URL}/carousel/lanscape2/1.jpg`,
    `${config.S3_URL}/carousel/lanscape2/2.jpg`,
    `${config.S3_URL}/carousel/lanscape2/3.jpg`,
    `${config.S3_URL}/carousel/lanscape2/4.jpg`,
    `${config.S3_URL}/carousel/lanscape2/5.jpg`,
    `${config.S3_URL}/carousel/lanscape2/6.jpg`,
    `${config.S3_URL}/carousel/lanscape2/7.jpg`,
    `${config.S3_URL}/carousel/lanscape2/8.jpg`,
    `${config.S3_URL}/carousel/lanscape2/9.jpg`,
    `${config.S3_URL}/carousel/lanscape2/10.jpg`,
  ];

  const [images, setImages] = useState([])

  const shuffleArray = (array) => {
    return array
      .map((item) => ({ item, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ item }) => item);
  };

  useEffect(() => {
    // Shuffle images based on orientation
    setImages(shuffleArray(images1));
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const next = useCallback(() => {
    setIsTransitioning(true);
    setCurrentIndex((prev) => (prev + 1) % images.length);
    setTimeout(() => setIsTransitioning(false), 500);
  }, [images.length]);

  const prev = () => {
    setIsTransitioning(true);
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
    setTimeout(() => setIsTransitioning(false), 500);
  };

  useEffect(() => {
    let timer;
    if (!isHovered) {
      timer = setInterval(next, interval);
    }
    return () => clearInterval(timer);
  }, [isHovered, interval, next]);

  const getVisibleDots = () => {
    const prevDot = (currentIndex - 1 + images.length) % images.length;
    const nextDot = (currentIndex + 1) % images.length;
    return [prevDot, currentIndex, nextDot];
  };

  return (
    <div
      className="relative w-full h-full md:h-[75vh] overflow-hidden group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Images */}
      <div className="w-full h-full flex items-center justify-center">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="w-full h-full object-cover object-right-top transition-opacity duration-500 ease-in-out"
        />
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prev}
        className="absolute left-4 z-10 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg"
        aria-label="Previous slide"
      >
        <ChevronLeft className="md:w-6 md:h-6 h-4 w-4" />
      </button>

      <button
        onClick={next}
        className="absolute right-4 z-10 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg"
        aria-label="Next slide"
      >
        <ChevronRight className="md:w-6 md:h-6 h-4 w-4" />
      </button>

      {/* Sliding Dot Indicators */}
      <div className="absolute z-10 bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        <div className="flex gap-2 relative">
          {getVisibleDots().map((dotIndex, i) => (
            <div
              key={dotIndex}
              className={`h-2 rounded-full transition-all duration-500 ease-out transform ${
                i === 1 ? 'w-8 bg-white' : 'w-2 bg-white/50'
              }`}
              style={{
                transform: `translateX(${isTransitioning ? (i - 1) * 8 : 0}px)`,
                opacity: isTransitioning && (i === 0 || i === 2) ? 0.5 : 1,
              }}
            >
              <div
                className={`h-full w-full rounded-full transition-transform duration-300 ${
                  isTransitioning ? 'scale-x-90' : 'scale-x-100'
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SimpleCarousel;
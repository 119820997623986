import React from "react";
import line from "../../assets/1.png"
import { Helmet } from "react-helmet";
import blog1 from "../../assets/blog-the-shubham.webp"

const Blog1 = () => {
  return (
    <div className="flex flex-col cel2 justify-center items-center bg-white p-5">
      <Helmet>
        <title>The Shubham Group's Journey to Creating Dream Homes in the Hills</title>
        <meta
          name="description"
          content="The story behind The Shubham Group’s journey. Explore our path to success, our core values, and our commitment to excellence being a Real Estate Developer"
        />
      </Helmet>
      <img
        className="p-3 md:p-5 xl:p-10 w-full max-w-6xl rounded-lg shadow-lg transition-transform transform hover:scale-105"
        src={blog1}
        alt="The Shubham Group Journey"
      />
      <div className="w-full max-w-6xl my-10 flex flex-col gap-5">
        <div className="text-2xl cel font-bold md:text-4xl flex flex-col items-center bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent text-center">
          <h1 className="cel2">The Shubham Group Journey to Creating Dream Homes in the Hills</h1>
      <img src={line} alt="" className=" mt-3 h-6"/>
        </div>

        <h2 className="text-lg md:text-3xl bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent  font-semibold pt-5 text-center">
          Startup Journey of The Shubham Group
        </h2>
        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          The Shubham Group was started by Mr. Agam Aggarwal in 2009 with a
          vision of creating value in real estate. When the company started,
          there was some gap in the market for the concept of second homes on
          hill stations for people living in big metropolises like Delhi and the
          NCR. Some of these individuals had a strong desire to travel to the
          mountains often and wished to have a house in the lap of the
          Himalayas. This would make it possible for them to spend their leisure
          time in nature's complete peace and beauty. The company began its
          first residential project in the vicinity of Nainital for this reason,
          and to date, it has completed two such projects in Uttarakhand and one
          in Himachal Pradesh with completely satisfied and happy clients.
        </p>

        <h3 className="text-lg md:text-3xl bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent  font-semibold pt-5 text-center">
          Creating Value in Real Estate for Our Potential Clients
        </h3>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Understanding Client Needs:</span>{" "}
          Your vision is our blueprint. We listen and tailor our projects to
          reflect what truly matters to you, whether it’s a cozy retreat or a
          spacious villa. For us, building homes means creating spaces that feel
          uniquely yours.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Prime Locations:</span> At The Shubham
          Group, we select locations that truly feel like home, ensuring every
          view and moment offers peace and beauty. We know that a perfect
          getaway begins with the perfect spot.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Sustainable Living:</span> We’re
          dedicated to protecting the beautiful surroundings of our projects. By
          using eco-friendly materials and designing homes that fit naturally
          into the landscape, we make sure that your investment helps create a
          greener, more sustainable future.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Customer Satisfaction:</span> Your
          satisfaction is our top priority. From the first meeting to settling
          into your new home, we’re here to guide you every step of the way,
          ensuring a smooth and enjoyable experience. We’re committed to making
          sure your journey with us is as exceptional as the home we create for
          you.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Return on Investment:</span> We know
          buying a home is a big investment, and we take it seriously. Our
          properties are designed to provide immediate comfort and long-term
          value. Beyond being a beautiful retreat, our holiday homes offer a
          great chance to earn rental income. With their prime locations and
          high demand, they not only increase in value but also provide a steady
          return.
        </p>

        <h4 className="text-lg md:text-3xl bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-semibold pt-5 text-center">
          The Idea Behind Developing Projects on Hill Stations
        </h4>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          Most clients we deal with are residents of Delhi, NCR, and most aspire
          to build a house in the hills as a vacation home or a retirement home
          for their future.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Vacation Homes:</span> Building
          vacation homes or holiday apartments for people looking to escape city
          life. The concept of vacation homes on hill stations revolves around
          creating a serene and comfortable retreat for individuals and families
          looking to escape the hustle and bustle of urban life.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Retirement Homes:</span> Creating a
          retirement home for the elderly was one of our objectives. Part of our
          population has achieved so much in their lives, and they are
          financially sound. Now, they are looking for a place to retire.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Scenic Beauty:</span> Offering
          stunning views of mountains, valleys, forests, or lakes was when we
          first thought about launching our first project on hills. People
          invest with us to ensure homes are close to natural attractions like
          waterfalls, hiking trails, and wildlife.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">A Pause from Reality:</span> Living in
          metropolitan cities is so busy with our daily jobs and commutes, and
          we don’t get time to enjoy simple things like nature, one of the most
          essential things for any individual. Every one of us needs time for
          self-healing and realizing where our lives are headed.
        </p>

        <h5 className="text-lg md:text-3xl bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-semibold pt-5 text-center">
          Customized, Tailored Construction for Everyone
        </h5>
        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          We believe each one to be its own. Each person's personality is
          different. Everyone has a different mindset when looking at things, so
          why do they have the same construction and design?
        </p>
        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          <span className="font-semibold">Scenic Beauty:</span> Offering
          stunning views of mountains, valleys, forests, or lakes was when we
          first thought about launching our first project on hills. People
          invest with us to ensure homes are close to natural attractions like
          waterfalls, hiking trails, and wildlife.
        </p>

        <p className="text-md md:text-xl px-3  text-justify rounded-2xl shadow-sm hover:bg-gray-50 transition-colors">
          Most clients we deal with are residents of Delhi, NCR, and most aspire
          to build a house in the hills as a vacation home or a retirement home
          for their future.
        </p>
      </div>
    </div>
  );
};

export default Blog1;

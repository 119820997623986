import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CommonCard from "../card";
import images from  "../../assets/AAVEDAM.png"
import line from "../../assets/1.png"

const projects = [
  {
    id: 1,
    title: "Aavedam Banquets",
    location: "Delhi NCR",
    imgSrc: images,
    link: "",
  },
];

function ProjectBenquet() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div data-aos-anchor-placement="top-center" data-aos="fade-up">
      <div
            className="text-3xl text-center cel2 mb-5 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent
"
          >
        Banquets
        <img src={line} alt="" className=" mx-auto h-4 mt-3" />
      </div>

      {/* (Project Component-2) */}
      <div className="flex flex-col">
        <section className="grid gap-5 md:m-auto p-3 md:gap-20 m-12 -mt-3">
          {projects.map((project) => (
            <div className="w-full" key={project.id}>
              <CommonCard
                title={project.title}
                location={project.location}
                imgSrc={project.imgSrc}
                link={project.link}
              />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

export default ProjectBenquet;

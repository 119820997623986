import React from "react";
// import line from "../assets/line1.svg"
import line from "../assets/line2.svg"
import line2 from "../assets/1.png"

function MainHeading() {
  return (
    <div
      className="relative mx-auto flex flex-col justify-center w-fit mt-[26px] md:mt-[12px] lg:mt-[12px]"
      
    >
      <h1
        className="text-3xl cel md:text-5xl uppercase lg:text-6xl mx-auto text-center bg-gradient-to-r from-[#B38728] via-[#FCAF17] to-[#B38728] bg-clip-text text-transparent font-bold"
        style={{
          marginTop: "12px",
        }}
      >
        The Shubham Group
      </h1>

      {/* <img src={line} alt="" className=" h-12"/> */}
      <img src={line2} alt="" className=" w-[400px] mx-auto"/>
      <h2
        className="text-sm arvo md:text-2xl mx-auto text-center mb-0 pb-0 px-2 text-gray-600"

      >
        Real Estate, Construction, Resorts and Collaboration
      </h2>
    </div>
  );
}

export default MainHeading;
